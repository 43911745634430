import * as React from 'react'
import { Form, Select } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import {
  aboutUsList
} from '../../service/index'

const { Option } = Select

export type IAboutUsAddProps = {
  onNextStep?: (e: any) => void
  aboutId?: any
  icon: string
  buttonTitle: string
  onSuccess: () => any
} & FormComponentProps

type IAboutUsAddStates = {
  loading: boolean
  aboutContent: any
}

class AboutUsAdd extends React.Component<IAboutUsAddProps, IAboutUsAddStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      loading: false,
      aboutContent: '',
    }
  }

  componentDidMount() {
    this.getAboutUs()
  }

  getAboutUs = async () => {
    this.setState({ loading: true })
    const params = {page: 1, rows: 10, aboutType: 1}
    const res = await aboutUsList(params)
    if (res.success) {
      const item = res.data[0]
      if (item) {
        const aboutContent = item.aboutContent
        setTimeout(() => {
          this.setState({
            aboutContent: aboutContent
          })
        }, 0)
      }
    }
    this.setState({
      loading: false,
    })
  }
  public render() {
    const { aboutContent } = this.state!
    return (
      <div className="app_layout_main">
        <div className="mt-l">
          <span dangerouslySetInnerHTML={{ __html: aboutContent }}></span>
        </div>
      </div>
    )
  }
}

export default Form.create<IAboutUsAddProps>()(AboutUsAdd)
