import React from 'react'
import { Card, Avatar, Tag, Tabs } from 'antd'
import Breadcrumb from '../Breadcrumb'
import { policyCodeDetail } from '../../service/index'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { FormComponentProps } from 'antd/lib/form'
import { IMAGE_SERVER } from '../../service/config'
import draftToHtml from 'draftjs-to-html'

const { TabPane } = Tabs

export interface IPolicyCodeDetailProps extends FormComponentProps, RouteComponentProps<any> {}

interface IPolicyCodeDetailStates {
  policyId: String
  policyCode: any
}

class PolicyCodeDetail extends React.Component<IPolicyCodeDetailProps, IPolicyCodeDetailStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      policyId: props.query.id,
      policyCode: {},
    }
  }

  componentDidMount() {
    this.getPolicyCodeDetail()
  }

  async getPolicyCodeDetail() {
    const params = { policyId: this.state.policyId }
    const res = await policyCodeDetail(params)
    if (res.success) {
      setTimeout(() => {
        this.setState({
          policyCode: res.data[0],
        })
      }, 0)
    }
  }

  public render() {
    const { policyCode } = this.state
    return (
      <div className="app_layout_main">
        <Breadcrumb />
        <div className="policyCode-detail">
          <Card>
            <div className="header-center">
              <div className="title">{policyCode.policyTitle}</div>
            </div>
            <div className="flex-wrapper mt-m">{<span dangerouslySetInnerHTML={{ __html: policyCode.policyContent }}></span>}</div>
          </Card>
        </div>
      </div>
    )
  }
}

export default PolicyCodeDetail
