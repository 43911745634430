import * as React from 'react'
import { Avatar, Layout, Tabs, List, Modal, Card, Divider, Button, Empty, Table } from 'antd'
import {
  myOrderList,
  userDetail,
  orderCancel,
  favorList,
  courseList,
  orderStuList,
} from './../../service/index'
import { IMAGE_SERVER } from '../../service/config'
import { message } from 'antd'
import { Link } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import EditProfile from './EditProfile'
import ModPassword from './ModPassword'
import { hasLogin } from '../../utils'
import Login from '../pages/Login'
import Invoice from '../order/Invoice'
import CertsDetail from '../order/CertsDetail'
const { Content } = Layout
const { TabPane } = Tabs
const { confirm } = Modal
const { Column } = Table

export interface IUserCenterProps extends RouteComponentProps<any> {}
interface IUserCenterStates {
  orderList: any[]
  favorCourseList: any[]
  favorPlanList: any[]
  favorTrainList: any[]
  page: any
  pageSize: any
  total: any
  orderStatus: any
  payStatus: any
  user: any
  tabSelect: any
  login: boolean
  stuOrderList: any
  loading: boolean
}

class UserCenter extends React.Component<IUserCenterProps, IUserCenterStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      login: hasLogin(),
      orderList: [],
      favorCourseList: [],
      favorPlanList: [],
      favorTrainList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      orderStatus: '',
      payStatus: '',
      user: { stuName: '', phone: '', rewardPoints: 0, compName: '' },
      tabSelect: '1',
      stuOrderList: [],
      loading: false,
    }
    this.onPageChange = this.onPageChange.bind(this)
  }

  componentDidMount() {
    if (hasLogin()) {
      this.getData()
      this.getUserDetail()
    }
  }
  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getOrderStuList()
    }, 0)
  }

  getData() {
    if (this.state.tabSelect === '1') {
      this.getOrderList()
    } else if (this.state.tabSelect === '4') {
      this.getOrderStuList()
    } else {
      this.getFavorList()
    }
  }
  async getOrderStuList() {
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
      studentId: this.state.user.studentId,
    }
    this.setState({ loading: true })
    const res = await orderStuList(params)

    if (res.success) {
      this.setState({
        stuOrderList: res.data,
        loading: false,
        total: res.total,
      })
    }
    this.setState({ loading: false })
  }

  callback(key: any) {
    setTimeout(() => {
      let params = {}
      switch (key) {
        case '1':
          params = {
            payStatus: '',
            orderStatus: '',
          }
          break
        case '2':
          params = {
            payStatus: 1,
            orderStatus: 1,
          }
          break
        case '3':
          params = {
            orderStatus: 1,
            payStatus: 5,
          }
          break
        case '4':
          params = {
            payStatus: 2,
            orderStatus: 2,
          }
          break
        case '5':
          params = {
            orderStatus: 4,
          }
          break
        default:
          break
      }
      this.setState({
        ...params,
        page: 1,
      })

      this.getOrderList(params)
    }, 0)
  }

  async getOrderList(statusParam?: any) {
    if (!statusParam) {
      statusParam = {
        payStatus: this.state.payStatus,
        orderStatus: this.state.orderStatus,
      }
    }
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
      ...statusParam,
    }
    const res = await myOrderList(params)
    if (res.msgStatsNum === 10) {
      message.warn('您还未登录！')
      return
    }
    if (res.success) {
      const orderList = res.data
      this.setState({
        orderList: orderList,
        total: res.total,
      })
    }
  }

  async getFavorList() {
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
    }
    const res = await favorList(params)

    if (res.success) {
      const { coutJson, itemJson, planJson } = res.data[0]
      this.setState({
        favorCourseList: coutJson,
        favorTrainList: itemJson,
        favorPlanList: planJson,
        total: res.total,
      })
    }
  }
  /**
   * 取消订单
   * @param orderId
   */
  async cancelOrder(orderId: any) {
    const params = {
      orderId: orderId,
    }
    const res = await orderCancel(params)

    if (res.success) {
      message.success('取消成功')
      setTimeout(() => {
        this.setState({
          page: this.state.page,
        })
        this.getOrderList()
      }, 0)
    } else {
      message.success('取消失败')
    }
  }

  async getUserDetail() {
    const params = {}
    const res = await userDetail(params)
    if (res.success && res.data && res.data.length > 0) {
      const accJson = res.data[0].accJson
      if (accJson && accJson.length > 0) {
        this.setState({
          user: accJson[0],
        })
      }
    }
  }

  orderCell(order: any) {
    let status = <span></span>
    let opera = <div></div>
    switch (order.orderStatus) {
      case 1:
        status = <span className="text-yellow">审核中</span>
        switch (order.payStatus) {
          case 1:
            status = <span className="text-yellow">未支付</span>
            opera = (
              <div>
                {/* <Link to={'/app/train/orderPay?orderId=' + order.orderId} color="gray">
                  <Button type="primary">去支付</Button>
                </Link>*/}
                <Button
                  type="danger"
                  className="ml-s"
                  ghost
                  onClick={() => {
                    const self = this
                    confirm({
                      title: '确定要取消订单吗?',
                      onOk() {
                        self.cancelOrder(order.orderId)
                      },
                      onCancel() {
                        console.log('Cancel')
                      },
                    })
                  }}
                >
                  取消订单
                </Button>
              </div>
            )
            break
          case 2:
            status = <span className="text-yellow">审核中</span>
            break
          case 3:
            status = <span className="text-red">已取消</span>
            break
        }
        break
      case 2:
        status = <div className="text-green">审核通过</div>
        let url = ''
        if (order.orderType == 1) {
          url = '/app/train/course?id='
        } else if (order.orderType == 2) {
          url = '/app/train/train?id='
        } else if (order.orderType == 3) {
          url = '/app/train/plan?id='
        }
        opera = (
          <Link to={url + order.orderTypeId} color="gray">
            <Button className="ml-m" type="primary">
              开始学习
            </Button>
          </Link>
        )
        break
      case 3:
        status = <span className="text-red">审核不通过</span>
        break
      case 4:
        status = <span className="text-gray">已取消</span>
        break
      default:
        break
    }

    return (
      <Card
        style={{ width: '100%' }}
        className="order-cell"
        title={
          <div className="order-header">
            <span>{order.regtime}</span>
            <span className="ml-m">订单号：{order.orderNum}</span>
          </div>
        }
      >
        <div className="order-content">
          <div className="left">
            <img className="img" src={IMAGE_SERVER + order.coverDirectURL} alt="" />
            <div className="title">{order.orderTitle}</div>
          </div>
          <div className="right">
            <Divider type="vertical" />
            <div className="price">￥{order.totalAmount}</div>
            <Divider type="vertical" />
            <div className="status">{status}</div>
            <Divider type="vertical" />
            <Link to={'/app/user/orderDetail?orderId=' + order.orderId}>
              <Button type="primary" className="mr-s">
                查看订单
              </Button>
            </Link>
            {opera}
          </div>
        </div>
      </Card>
    )
  }

  public render() {
    const paperStatusStr = (paperStatus: any) => {
      let paperStatusStr = ''
      switch (paperStatus) {
        case 1:
          paperStatusStr = '无考试'
          break
        case 2:
          paperStatusStr = '待出'
          break
        case 3:
          paperStatusStr = '合格'
          break
        case 4:
          paperStatusStr = '不合格'
          break
        default:
          break
      }
      return paperStatusStr
    }

    return (
      <div>
        {!this.state.login && (
          <div className="app_layout_main">
            <div className="mt-m">
              <Card style={{ width: '100%', height: 700 }}>
                <div className="flex-center w-h-100">
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{}}
                    description={<span>您还未登录哦！</span>}
                  >
                    <Login buttonTitle="去登录" />
                  </Empty>
                </div>
              </Card>
            </div>
          </div>
        )}
        {this.state.login && (
          <div>
            <div className="uc_header">
              <div className="uc_header_content">
                <div className="flex-h">
                  <Avatar
                    size={64}
                    className="mr-m"
                    src={IMAGE_SERVER + this.state.user.photoDirectURL}
                  />
                  <div>
                    <div className="name">{this.state.user.stuName}</div>
                    <div className="company mt-s">公司：{this.state.user.compName}</div>
                    <div className="point mt-s">积分：{this.state.user.rewardPoints}</div>
                  </div>
                </div>
                <div className="flex-h-bottom">
                  <EditProfile
                    icon="user"
                    studentId={this.state.user.studentId}
                    buttonTitle="完善资料"
                    onSuccess={() => {}}
                  />

                  <ModPassword icon={'key'} buttonTitle={'修改密码'} onSuccess={function () {}} />
                </div>
              </div>
            </div>
            <div className="app_layout_main stu_center mt-m">
              <Tabs
                tabPosition="left"
                type="card"
                onChange={(key: any) => {
                  setTimeout(() => {
                    this.setState({
                      tabSelect: key,
                    })
                    this.getData()
                  }, 0)
                }}
              >
                <TabPane tab="我的订单" key="1">
                  <Content>
                    <div>
                      <Tabs defaultActiveKey="1" onChange={(key: any) => this.callback(key)}>
                        <TabPane tab="全部订单" key="1"></TabPane>
                        <TabPane tab="待支付" key="2"></TabPane>
                        <TabPane tab="审核中" key="3"></TabPane>
                        <TabPane tab="已完成" key="4"></TabPane>
                        <TabPane tab="已取消" key="5"></TabPane>
                      </Tabs>
                    </div>
                    <List
                      size="large"
                      bordered={false}
                      dataSource={this.state.orderList}
                      renderItem={(item) => <List.Item>{this.orderCell(item)}</List.Item>}
                      pagination={{
                        onChange: (page) => {
                          setTimeout(() => {
                            this.setState({
                              page: page,
                            })
                            this.getOrderList()
                          }, 0)
                        },
                        current: this.state.page,
                        total: this.state.total,
                      }}
                    />
                  </Content>
                </TabPane>
                <TabPane tab="成绩和证书" key="4">
                  <Tabs defaultActiveKey="1" onChange={(key: any) => this.callback(key)}>
                    <TabPane tab="成绩" key="1">
                      <Table
                        className="mt-m"
                        rowKey="courseId"
                        size="small"
                        dataSource={this.state.stuOrderList}
                        loading={this.state.loading}
                        pagination={{
                          current: this.state.page,
                          onChange: this.onPageChange,
                          pageSize: this.state.pageSize,
                          size: 'small',
                          total: this.state.total,
                          showTotal: (total) => `共 ${total} 条`,
                          showQuickJumper: true,
                          onShowSizeChange: (current, size) => {
                            setTimeout(() => {
                              this.setState({
                                pageSize: size,
                              })
                              this.onPageChange(1)
                            }, 0)
                          },
                        }}
                      >
                        <Column
                          title="报名名称"
                          dataIndex="orderTitle"
                          key="orderTitle"
                          render={(orderTitle: any) => orderTitle}
                        />
                        <Column
                          title="报名时间"
                          dataIndex="regtime"
                          key="regtime"
                          render={(regtime: any) => regtime}
                        />
                        <Column
                          title="考试结果"
                          dataIndex="paperStatus"
                          key="paperStatus"
                          render={(paperStatus: any) => {
                            let paperStatusStr = ''
                            switch (paperStatus) {
                              case 1:
                                paperStatusStr = '无考试'
                                break
                              case 2:
                                paperStatusStr = '待出'
                                break
                              case 3:
                                paperStatusStr = '合格'
                                break
                              case 4:
                                paperStatusStr = '不合格'
                                break
                              default:
                                break
                            }
                            return paperStatusStr
                          }}
                        />
                      </Table>
                    </TabPane>
                    <TabPane tab="证书" key="2">
                      <CertsDetail studentId={this.state.user.studentId}></CertsDetail>
                    </TabPane>
                  </Tabs>
                </TabPane>
                <TabPane tab="我的收藏" key="2">
                  <Tabs defaultActiveKey="1" onChange={(key: any) => this.callback(key)}>
                    <TabPane tab="课程" key="1">
                      <div className="flex-wrapper">
                        {this.state.favorCourseList &&
                          this.state.favorCourseList.map((item: any) => (
                            <Link
                              to={'/app/train/list/course?id=' + item.favoriteTypeId}
                              color="gray"
                            >
                              <div className="course-box mt-m mr-m">
                                <img
                                  className="img"
                                  src={IMAGE_SERVER + item.coverDirectURL}
                                  alt=""
                                />
                                <div className="title">{item.courseTitle}</div>
                              </div>
                            </Link>
                          ))}
                      </div>
                    </TabPane>
                    <TabPane tab="项目" key="2">
                      <div className="flex-wrapper">
                        {this.state.favorTrainList &&
                          this.state.favorTrainList.map((item: any) => (
                            <Link
                              to={'/app/train/list/train?id=' + item.favoriteTypeId}
                              color="gray"
                            >
                              <div className="course-box mt-m mr-m">
                                <img
                                  className="img"
                                  src={IMAGE_SERVER + item.coverDirectURL}
                                  alt=""
                                />
                                <div className="title">{item.itemTitle}</div>
                              </div>
                            </Link>
                          ))}
                      </div>
                    </TabPane>
                    <TabPane tab="计划" key="3">
                      <div className="flex-wrapper">
                        {this.state.favorPlanList &&
                          this.state.favorPlanList.map((item: any) => (
                            <Link
                              to={'/app/train/list/plan?id=' + item.favoriteTypeId}
                              color="gray"
                            >
                              <div className="course-box mt-m mr-m">
                                <img
                                  className="img"
                                  src={IMAGE_SERVER + item.coverDirectURL}
                                  alt=""
                                />
                                <div className="title">{item.planTitle}</div>
                              </div>
                            </Link>
                          ))}
                      </div>
                    </TabPane>
                  </Tabs>
                </TabPane>
                {/* <TabPane tab="我的发票" key="3">
                  <Invoice />
                </TabPane>*/}
              </Tabs>
            </div>
          </div>
        )}
      </div>
    )
  }
}
export default withRouter(UserCenter)
