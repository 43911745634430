/**
 * Created by hao.cheng on 2017/4/28.
 */
// 获取url的参数
import umbrella from 'umbrella-storage'
export const queryString = () => {
  let _queryString: { [key: string]: any } = {}
  const _query = window.location.search.substr(1)
  const _vars = _query.split('&')
  _vars.forEach((v, i) => {
    const _pair = v.split('=')
    if (!_queryString.hasOwnProperty(_pair[0])) {
      _queryString[_pair[0]] = decodeURIComponent(_pair[1])
    } else if (typeof _queryString[_pair[0]] === 'string') {
      const _arr = [_queryString[_pair[0]], decodeURIComponent(_pair[1])]
      _queryString[_pair[0]] = _arr
    } else {
      _queryString[_pair[0]].push(decodeURIComponent(_pair[1]))
    }
  })
  return _queryString
}

export const AlphaOptions = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

/**
 * 校验是否登录
 * @param permits
 */
export const checkLogin = (permits: any): boolean =>
  (process.env.NODE_ENV === 'production' && !!permits) ||
  (process.env.NODE_ENV === 'development' && !!permits)

export const hasLogin = () => {
  let user = umbrella.getLocalStorage('user')
  if (user) {
    return true
  } else {
    return false
  }
}

export enum GroupType {
  STU = 1, // 学员
  COURSE_WARE, // 课件
  COURSE, // 课程
  QUES, // 问题
  TEST, // 试卷
  PASS_TEST, // 闯关
}

/**
 * 题型
 */
export enum QuesType {
  SINGLE_SEL = 1,
  MULTI_SEL,
  TRUE_FLS,
  COMPLE,
  QUES,
}

export const quesTypeArr = [
  {
    key: QuesType.SINGLE_SEL,
    name: '单选题',
  },
  {
    key: QuesType.MULTI_SEL,
    name: '多选题',
  },
  {
    key: QuesType.TRUE_FLS,
    name: '判断题',
  },
  {
    key: QuesType.COMPLE,
    name: '填空题',
  },
  {
    key: QuesType.QUES,
    name: '问答题',
  },
]

export const quesTypeName = (type: QuesType): any => {
  const ques = quesTypeArr.find((item: any) => item.key == type)
  return ques ? ques.name : ''
}

export function parseTime(time: any, cFormat: any) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj: any[string] = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result: any, key: any) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

export const getFileType = (fileName: any) => {
  // 后缀获取
  let suffix = ''
  // 获取类型结果
  let result: any = ''
  try {
    const flieArr = fileName.split('.')
    suffix = flieArr[flieArr.length - 1]
  } catch (err) {
    suffix = ''
  }
  // fileName无后缀返回 false
  if (!suffix) {
    return false
  }
  suffix = suffix.toLocaleLowerCase()
  // 图片格式
  const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
  // 进行图片匹配
  result = imglist.find((item) => item === suffix)
  if (result) {
    return 'image'
  }
  // 匹配txt
  const txtlist = ['txt']
  result = txtlist.find((item) => item === suffix)
  if (result) {
    return 'txt'
  }
  // 匹配 excel
  const excelist = ['xls', 'xlsx']
  result = excelist.find((item) => item === suffix)
  if (result) {
    return 'excel'
  }
  // 匹配 word
  const wordlist = ['doc', 'docx']
  result = wordlist.find((item) => item === suffix)
  if (result) {
    return 'word'
  }
  // 匹配 pdf
  const pdflist = ['pdf']
  result = pdflist.find((item) => item === suffix)
  if (result) {
    return 'pdf'
  }
  // 匹配 ppt
  const pptlist = ['ppt', 'pptx']
  result = pptlist.find((item) => item === suffix)
  if (result) {
    return 'ppt'
  }
  // 匹配 视频
  const videolist = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v']
  result = videolist.find((item) => item === suffix)
  if (result) {
    return 'video'
  }
  // 匹配 音频
  const radiolist = ['mp3', 'wav', 'wmv']
  result = radiolist.find((item) => item === suffix)
  if (result) {
    return 'radio'
  }
  // 其他 文件类型
  return 'other'
}

export const getQueryVariable = (variable: any, search: any) => {
  var query = search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}

export const getUrlKey = (variable:any) => {
  var index = window.location.href.indexOf("?");//获取地址栏路径并进行分割
  var query = window.location.href.substr(
    index + 1,
    window.location.href.length
  );
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

export function str2Base64 (str: any) {
  // 避免中文乱码
  const encodeRes = encodeURI(str)
  // 中文转化base64
  return btoa(encodeRes)
}

export function base642Str (base64: any) {
  // 避免中文乱码
  const encodeRes = atob(base64)
  // 中文转化base64
  return decodeURI(encodeRes)
}