import * as React from 'react'
import { certsList } from './../../service/index'
import { Descriptions, Table, Card, Row, Col, Form } from 'antd'
import { IMAGE_SERVER } from './../../service/config'
import BreadcrumbCustom from '../BreadcrumbCustom'
import { Link } from 'react-router-dom'
import { CSSProperties } from 'react'

const { Column } = Table

export interface IOrderDetailProps {
  studentId: any
}
export interface IOrderDetailStates {
  loading: boolean
  applyStuList: any[]
  fileList: any[]
  previewVisible: boolean
  previewImage: any
  certsDetail: any
}

export default class OrderDetail extends React.Component<IOrderDetailProps, IOrderDetailStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      loading: false,
      applyStuList: [],
      fileList: [],
      previewVisible: false,
      previewImage: '',
      certsDetail: {},
    }
  }
  componentDidMount() {
    this.getStuOrder()
  }

  getStuOrder = async () => {
    if (this.props.studentId) {
      this.setState({ loading: true })
      const res = await certsList({ studentId: this.props.studentId })
      if (res.success) {
        const certs = res.data[0]
        if (certs) {
          this.setState({
            certsDetail: certs,
          })
        }
      }
      this.setState({
        loading: false,
      })
    }
  }

  public render() {
    //订单状态
    const orderStatusStr = (orderStatus: any) => {
      let orderStatusStr = ''
      switch (orderStatus) {
        case 1:
          orderStatusStr = '有效'
          break
        case 2:
          orderStatusStr = '无效'
          break
        /*  case 3:
          orderStatusStr = '审核不通过'
          break
        case 4:
          orderStatusStr = '已取消'
          break*/
        default:
          break
      }
      return orderStatusStr
    }
    //证件类型
    const ccieTypeStr = (ccieType: any) => {
      let ccieTypeStr = ''
      switch (ccieType) {
        case 1:
          ccieTypeStr = '身份证'
          break
        default:
          break
      }
      return ccieTypeStr
    }
    //证件号码
    const ccieNumStr = (ccieType: any) => {
      let ccieNumStr = ''
      switch (ccieType) {
        case 1:
          ccieNumStr = this.state.certsDetail.cardID
          break
        default:
          break
      }
      return ccieNumStr
    }
    const flex_row = { display: 'flex', flexDirection: 'row' } as CSSProperties
    return (
      <div>
        <Form layout="horizontal" labelAlign="left">
          <Row gutter={24} style={flex_row}>
            <Col style={{ minWidth: '200px' }}>
              <Form.Item label="姓名" style={flex_row}>
                {this.state.certsDetail.stuName}
              </Form.Item>
            </Col>
            <Col style={{ minWidth: '200px', marginLeft: '50px' }}>
              <Form.Item label="证件类型" style={flex_row}>
                {ccieTypeStr(this.state.certsDetail.ccieType)}
              </Form.Item>
            </Col>
            <Col style={{ minWidth: '200px', marginLeft: '50px' }}>
              <Form.Item label="证件号码" style={flex_row}>
                {ccieNumStr(this.state.certsDetail.ccieType)}
              </Form.Item>
            </Col>
          </Row>
          <Row style={flex_row}>
            <Col style={{ minWidth: '200px' }}>
              <Form.Item label="所属企业名称" style={flex_row}>
                {this.state.certsDetail.compName}
              </Form.Item>
            </Col>
            <Col style={{ flex: 1, marginLeft: '50px' }}>
              <Form.Item label="统一社会信用代码" style={flex_row}>
                {this.state.certsDetail.compCode}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} style={flex_row}>
            <Col style={{ minWidth: '200px' }}>
              <Form.Item label="资质类型" style={flex_row}>
                {this.state.certsDetail.ccieType == 1 ? '实验员' : ''}
              </Form.Item>
            </Col>
            <Col style={{ minWidth: '200px', marginLeft: '50px' }}>
              <Form.Item label="发证机关" style={flex_row}>
                {this.state.certsDetail.organName}
              </Form.Item>
            </Col>
            <Col style={{ minWidth: '200px', marginLeft: '50px' }}>
              <Form.Item label="证书编号" style={flex_row}>
                {this.state.certsDetail.ccieNo}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} style={flex_row}>
            <Col style={{ minWidth: '200px' }}>
              <Form.Item label="发证日期" style={flex_row}>
                {this.state.certsDetail.issueDate}
              </Form.Item>
            </Col>
            <Col style={{ minWidth: '200px' }}>
              <Form.Item label="有效期" style={flex_row}>
                {this.state.certsDetail.validEndDate}
              </Form.Item>
            </Col>
            <Col style={{ minWidth: '200px' }}>
              <Form.Item label="当前状态" style={flex_row}>
                {orderStatusStr(this.state.certsDetail.ccieStatus)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}
