import React, { CSSProperties } from 'react'
import { Avatar, Tag, Card, Pagination, Form, Popover, Button } from 'antd'
import { Link } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb'
import { homeExpertList } from '../../service/index'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connectAlita } from 'redux-alita'
import { FormComponentProps } from 'antd/lib/form'
import { IMAGE_SERVER } from '../../service/config'
import {
  aboutUsList
} from '../../service/index'

export interface IExpertListProps extends FormComponentProps, RouteComponentProps<any> {}

interface IExpertListStates {
  type: String
  querySearchText: any
  searchText: any
  expertList: any[]
  page: number
  pageSize: number
  total: number
  contactUs: any
}

class ExpertList extends React.Component<IExpertListProps, IExpertListStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      type: props.query.type,
      querySearchText: '',
      searchText: '',
      expertList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      contactUs: ''
    }
  }

  componentDidMount() {
    this.initData()
  }

  initData() {
    this.getExpertList()
    this.getAboutUs()
  }

  getAboutUs = async () => {
    const params = {page: 1, rows: 10, aboutType: 2}
    const res = await aboutUsList(params)
    if (res.success) {
      const item = res.data[0]
      if (item) {
        const aboutContent = item.aboutContent
        setTimeout(() => {
          this.setState({
            contactUs: aboutContent
          })
        }, 0)
      }
    }
  }

  async getExpertList() {
    const params = { page: this.state.page, rows: this.state.pageSize }
    const res = await homeExpertList(params)
    if (res.success) {
      setTimeout(() => {
        this.setState({
          expertList: res.data,
          total: res.total,
        })
      }, 0)
    }
  }

  public render() {
    const { contactUs } = this.state
    const gridStyle: CSSProperties = {
      textAlign: 'center',
      width: '185px',
      padding: 0,
      marginRight: '15px',
      borderRadius: '2px',
      marginTop: '10px',
    }
    return (
      <div className="app_layout_main">
        <Breadcrumb />
        <div className="flex-wrapper">
          {this.state.expertList.map((expert: any) => (
            <Card.Grid style={gridStyle}>
            <div className="teacher_box">
              <Link to={'/app/home/expert?id=' + expert.expertId} color="gray">
                <div className="flex-hcenter header">
                  <Avatar className="img" src={IMAGE_SERVER + expert.photoDirectURL} />
                  <div className="flex-hcenter">
                    <div className="title">{expert.expertName}</div>
                    <div className="sub_title">{expert.job}</div>
                  </div>
                </div>
                <p className="detail">{expert.abstract}</p>
              </Link>
              </div>
            </Card.Grid>
          ))}
        </div>
        <div className="flex-h-end mt-m">
          <Pagination
            current={this.state.page}
            pageSize={this.state.pageSize}
            total={this.state.total}
            onChange={(page: any) => {
              setTimeout(() => {
                this.setState({
                  page: page,
                })

                this.getExpertList()
              }, 0)
            }}
          />
        </div>
      </div>
    )
  }
}

// 重新设置连接之后组件的关联类型
const trainListForm = Form.create<IExpertListProps>()(ExpertList)
const ExpertListConnect: React.ComponentClass<IExpertListProps, IExpertListStates> = connectAlita([
  {},
])(trainListForm)
export default withRouter(ExpertListConnect)
