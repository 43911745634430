import * as React from 'react'
import { Card, Empty, Tag ,Pagination} from 'antd'
import { Link } from 'react-router-dom'
import { hasLogin } from '../../utils'
import { policyCodeList } from './../../service/index'
import { IMAGE_SERVER } from '../../service/config'

export interface IPolicyCodeProps {}

export interface IPolicyCodeStates {
  policyList: any[],
  typeList: any[],
  currentType: any,
  page: any,
  pageSize: any,
  total: any
}

const { CheckableTag } = Tag
export default class PolicyCode extends React.Component<IPolicyCodeProps, IPolicyCodeStates> {
  constructor(props: any) {
    super(props)

    const typeId = props.query.typeId
    this.state = {
      policyList: [],
      typeList: [
        {type: 0, label : '全部'},
        {type: 1, label : '智能制造政策汇编'},
        {type: 2, label : '未来工厂政策汇编'}, 
        {type: 3, label :'职业技能培训政策汇编'}, 
        {type: 4, label :'标准汇编'}],
      currentType: typeId || 0,
      page: 1,
      pageSize: 20,
      total: 0
    }
  }
  componentDidMount() {
    this.getList()
  }

  async getList() {
    const params = { 
      policyType: this.state.currentType === 0? null: this.state.currentType,
      page: this.state.page,
      rows: this.state.pageSize
    }
    const res = await policyCodeList(params)
    if (res.data) {
      this.setState({
        policyList: res.data,
        total: res.total,
      })
    }
  }

  public render() {
    return (
      <div className="app_layout_main">
        <div>
          <Card
            className="mt-m"
            headStyle={{ padding: 0 }}
            bodyStyle={{ padding: 0 }}
            bordered={false}
          >
            <div>
              {this.state.typeList.map((item: any) => (
                <CheckableTag
                  key={item.type}
                  checked={this.state.currentType == item.type}
                  onChange={(checked:any) => {
                    setTimeout(() => {
                      if (checked) {
                        this.setState({
                          currentType: item.type,
                        })
                      }
                      this.setState({
                        page: 1,
                      })
                      this.getList()
                    }, 0)
                  }}
                >
                  {item.label}
                </CheckableTag>
              ))}
            </div>
            {this.state.policyList.length === 0 && (
              <div className="flex-h-center w-h-100">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{}}
                  description={<span>暂时没有数据</span>}
                ></Empty>
              </div>
            )}
            <div className="flex-wrapper">
              
              {this.state.policyList &&
                this.state.policyList.map((item: any) => {
                  return (
                    <Link to={'/app/policy/detail?id=' + item.policyId} color="gray">
                      <div className="course-box mt-m mr-m">
                        <img className="img" src={IMAGE_SERVER + item.policyCoverURL} title={item.policyTitle} alt="" />
                        <div className="title" title={item.policyTitle}>{item.policyTitle}</div>
                      </div>
                    </Link>
                  )
                })}
            </div>
            <div className="flex-h-end mt-m">
          <Pagination
            current={this.state.page}
            total={this.state.total}
            pageSize={this.state.pageSize}
            onChange={(page: any) => {
              setTimeout(() => {
                this.setState({
                  page: page,
                })

                this.getList()
              }, 0)
            }}
          />
        </div>
          </Card>
        </div>
      </div>
    )
  }
}
